import axios from 'axios';

const clientsAPIURL = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BO_CLI_API;

const clientAPI = axios.create({
  baseURL: clientsAPIURL
});

export const setAuthHeader = (token) => {
  clientAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

clientAPI.interceptors.request.use((config) => {
  let authInfo = JSON.parse(localStorage.getItem('authInfo'));

  if (authInfo) {
    config.headers['Authorization'] = `Bearer ${ authInfo.access_token }`
  }

  return config;
}, error => {
  return Promise.reject(error)
});

export default clientAPI;
