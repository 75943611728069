/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import quotationAPI from '@/APIs/boQuotationAPI'

export default {
  listQuotations({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      quotationAPI.get(url, params)
        .then((response) => {
          commit('SET_QUOTATION', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  listQuotationClient({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '/list-client';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size,
          clientId: pageObject.clientId
        }
      };
      //console.log("params");
      //console.log(params);

      quotationAPI.get(url, params)
        .then((response) => {
          commit('SET_QUOTATION', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // Borrar
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      quotationAPI.post(`${item.id}`, { item: item })
        .then((response) => {
          commit('UPDATE_PRODUCT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Borrar
  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      quotationAPI.delete(`${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
