import clientPanelAPI from '@/APIs/clientPanelAPI'
import boClientAPI from '@/APIs/boClientAPI'

export default {
  
  listClient: function(page, size) {
    const url = '/';
    let params = {
      params: {
        page: page,
        size: size
      }
    };
    return boClientAPI.get(url, params);
  },
  bookingDocumentResponse: function (bookingId) {

    //return new Promise((resolve, reject) => {
      const url = '/documentExist/';

      const doc = 'bookingDocument_'+bookingId;

      var code = url+doc;
      //return code;

      const request = clientPanelAPI.get(code);

      return request;
       /* .then(response => {
          console.log(response.status);
          resolve(response);
          //return "response.status";
        })
        .catch(error => {
          reject(error);
          //return "error.status";
        });*/
    //});
  },
  clientResetPassword: function (clientId) {
    const url = '/resetpassword/'+clientId;

    let params = {
      params: {
        clientId: clientId
      }
    }
    return boClientAPI.get(url, params);

  },
  getClient: function (clientId) {
    const url = '/'+clientId;

    let params = {
      params: {
        clientId: clientId
      }
    }
    return boClientAPI.get(url, params);

  }

};