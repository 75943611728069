<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div class="w-full mt-5">
      <vs-row vs-justify="left" vs-col="10" class="vx-row w-full mt-5">
        <vs-col vs-justify="center" vs-w="12" class=" w-full mt-5">
          <vs-card fixed-height class="cardx">
            <template>
              <div class="vx-row mt-5">
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Nombre" v-model="name" />
                </div>
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Segundo Nombre" v-model="secondName" />
                </div>
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <br>
                    <label>RUT:</label>
                    <br>
                    {{identifier | rutFilter }}
                  <!--<vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="RUT" v-mask="'##.###.###-#'" v-model="identifier" />-->
                </div>
              </div>

              <div class="vx-row mt-5">
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Apellido" v-model="lastName" />
                </div>
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Apellido Materno" v-model="motherLastName" />
                </div>
              </div>

              <div class="vx-row mb-6">
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input type="email" class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border label="Email" v-model="contactData.email" />
                </div>
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border label="Telefono Celular" v-model="contactData.cellphoneNumber" v-mask="'# #### ####'" />
                </div>

                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border label="Telefono" v-model="contactData.phoneNumber" v-mask="'# #### ####'" />
                </div>
              </div>

              <div class="vx-row mb-6">
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Profesión" v-model="profession" />
                </div>
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Nacionalidad" v-model="nationality" />
                </div>

                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border label="Estado Civil" v-model="maritalStatus" />
                </div>
              </div>

              <div class="vx-row mb-6">
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border label="Calle" v-model="contactData.address.street" />
                </div>
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border label="Numero" v-model="contactData.address.number" />
                </div>

                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border label="Departamento" v-model="contactData.address.unitNumber" />
                </div>

              </div>

              <div class="vx-row mb-6">
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-select autocomplete v-model="selectedComuna" class="w-full select-large" label="Comuna" name="city">
                    <vs-select-item :key="index" :value="item" :text="item.value" v-for="(item, index) in cityOptions" class="w-full" />
                  </vs-select>
                </div>
                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-select disabled class="w-full select-large" v-model="selectedRegion" :label="'Región: '+contactData.address.region" name="region">
                    <vs-select-item :key="region" :value="region" :text="region" v-for="region in [selectedComuna && selectedComuna.region]" class="w-full" />
                  </vs-select>
                </div>

                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <vs-input class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border label="Adicional" v-model="contactData.address.aditionalInfo" />
                </div>

                <div class="vx-col w-full mt-5 sm:w-1/3">
                  <br>
                  <vs-button class="mr-3 mb-2" @click="updateClient()">Guardar</vs-button>
                </div>
              </div>
            </template>
          </vs-card>
        </vs-col>


      </vs-row>
      <vs-row vs-justify="left" vs-col="10" class="vx-row w-full mt-5"><!-- QUOTATIONS -->

      <div id="data-list-list-view" class="data-list-container">
        <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="quotations">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            </div>
            <!-- ITEMS PER PAGE -->
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ quotations.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : quotations.length }} de {{ queriedItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage=5">
                  <span>5</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <template slot="thead">
            <vs-th sort-key="project">Proyecto</vs-th>
            <vs-th sort-key="quoteUnit">Unidad</vs-th>
            <vs-th sort-key="client">Cliente</vs-th>
            <vs-th sort-key="quotationTime">Fecha</vs-th>
            <vs-th sort-key="status">Estado</vs-th>
            <!--<vs-th>Acciones</vs-th>-->
            <vs-th>Exportar 
              <feather-icon icon="DownloadIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="exportToExcel()" />
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.projectId">
                  <p class="product-name font-medium truncate">{{ tr.project.name }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-category">{{ tr.quotationUnit }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-category">{{ tr.client.name }} {{ tr.client.lastName }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-category">{{ tr.quotationTime | formatDate }}</p>
                </vs-td>
                <vs-td>
                  <vs-chip :color="getOrderStatusColor(tr.status)" class="product-order-status">{{translateStatus(tr.status)}}</vs-chip>
                  <vs-chip :color="getDaysStatusColor(daysDifference(tr.quotationTime))" class="product-order-status">{{daysDifference(tr.quotationTime)}} dias de vigencia</vs-chip>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="retrievePdf(tr.id)" />Ver PDF
                  <br>
                  <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />Ver
                </vs-td>
                <template class="expand-user" slot="expand">
                    <div class="vx-row">
                      <!--Datos del Projecto -->
                      <vs-divider color="primary" icon-pack="feather" position="center">
                        Datos del Proyecto
                      </vs-divider>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <label>Nombre:</label>
                        <br>
                        {{tr.project.name}}
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <label>Cliente:</label>
                        <br>
                        {{ tr.client.name }} {{ tr.client.lastName }}
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <label>Unidad:</label>
                        <br>
                        {{tr.quotationUnit}}
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <label>Adicionales:</label>
                        <br>
                        {{tr.quotationAdditional}} {{tr.quotationWarehouse}}
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <label>Telefono:</label>
                        <br>
                        {{tr.client.contactData.cellphoneNumber}}
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <label>Email:</label>
                        <br>
                        {{tr.client.contactData.email}}
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <label>Valor de la Cotización:</label>
                        <br>
                        UF {{tr.amount| numeral('0, 0.00')}}
                      </div>
                      <div class="vx-col md:w-1/2 w-full mt-5">
                        <label>Observaciones:</label>
                        <br>
                        {{tr.additionalInfo}}
                      </div>
                      <!--Datos Adicionales -->
                      <vs-divider color="primary" icon-pack="feather" position="center">
                        Acciones
                      </vs-divider>
                      <div class="vx-col md:w-1/3 w-full mt-5">
                        <label>Ver PDF: </label>
                        <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="retrievePdf(tr.id)" />
                      </div>
                      <div class="vx-col md:w-1/3 w-full mt-5">
                        <label>Ver Cotización: </label>
                        <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" />
                      </div>
                      <div class="vx-col md:w-1/3 w-full mt-5">
                        <label>Eliminar Cotización: </label>
                        <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteProject(tr.id)" />
                      </div>
                    </div>
                </template>

              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
      </vs-row>
  </div>
</template>

<script>
import clientService from '@/services/ClientService.js';
import moduleQuotation from "@/store/quotation/moduleQuotation.js"


import Vue from 'vue';
import { rutFilter } from 'vue-dni';

import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, { locale: 'es' });


Vue.filter('rutFilter', rutFilter);

export default {
  components: {
    
  },
  data() {
    return {
      selectedComuna: null,
      //selectedRegion: null,

      activeItem: 3,
      selected: [],
      id: '',
      name: '',
      secondName: '',
      lastName: '',
      motherLastName: '',
      identifier: '',
      profession: '',
      nationality: '',
      maritalStatus: '',
      contactData: {
        address: {
          street: '',
          number: '',
          unitNumber: '',
          region: '',
          city: '',
          aditionalInfo: '',
        },
        phoneNumber: '',
        cellphoneNumber: '',
        email: '',
      },
      creationDate: '',
      lastUpdate: '',

      itemsPerPage: 5,
      isMounted: false,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},


      
      fileName: "",
      formats:["xlsx", "csv", "txt"] ,
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "Nombre", "Segundo Nombre", "Apellido", "Segundo Apellido", "RUT", "Email", "Telefono", "Celular",
        "Monto", 
        "Projecto", 
        "Estado", 
        "Porcentaje pie", 
        "Unidad", 
        "Adicional", 
        "Bodega", 
        "Numero Cuotas", 
        "Valor UF", 
        "Observaciones", 
        "Fecha"],
      headerVal: [
        "name", "secondName", "lastName", "motherLastName", "identifier", "email", "phoneNumber", "cellphoneNumber",
        "amount", 
        "projectName",
        "statusActual", 
        "footPercentage", 
        "quotationUnit", 
        "quotationAdditional", 
        "quotationWarehouse", 
        "quotesNumber", 
        "uf",
        "additionalInfo", 
        "actualDate"],
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    quotations() {
      //console.log("quotations");
      //console.log(this.$store.state.dataListQuotation.quotations);
      return this.$store.state.dataListQuotation.quotations
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.quotations.length
    }
  },
  created: function() {
    this.item = localStorage.getItem('clientId');
    //console.info(localStorage.getItem('clientId'));

    if (!moduleQuotation.isRegistered) {
      this.$store.registerModule('dataListQuotation',moduleQuotation)
      moduleQuotation.isRegistered = true
    }
    let pageObject = {
      page: 0,
      size: 200,
      clientId: this.item
    };
    this.$store.dispatch("dataListQuotation/listQuotationClient", pageObject)
  },
  beforeMount() {
    //console.info("beforeMount");
    //console.info(this.item);
    this.listClient(this.item);
  },
  methods: {
    retrieveDocument(clientId) {
      var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_CPA_API + clientService.rutDocumentResponse(clientId);
      return code;
      /*
      var win = window.open(code, '_blank');
      win.focus();*/
    },
    listClient: function(clientId) {
    //console.info("listClient");
    //console.info(clientId);
      clientService.getClient(clientId)
        .then(response => {
          if (response) {
            this.createClient(response.data);
          }
        })
        .catch(error => {
          this.$log.error('Error calling clientService.listClient :  - ', error);
        });
    },
    updateClient: function() {
      //this.contactData.address.region = this.selectedComuna.region;
      //console.log(this.contactData);
      if (this.selectedRegion != null) {
        this.contactData.address.region = this.selectedRegion;
        this.contactData.address.city = this.selectedComuna.value;
      }
      clientService.updateClient(this.name, this.secondName, this.lastName, this.motherLastName, this.identifier, 
        this.profession, this.nationality, this.maritalStatus, this.contactData)
        .then(response => {
          if (response) {
            this.createClient(response.data);
          }
        })
        .catch(error => {
          this.$log.error('Error calling clientService.listClient :  - ', error);
        });
    },
    createClient: function(client) {
      //console.log(client);
      this.id = client.id;
      this.name = client.name;
      this.secondName = client.secondName;
      this.lastName = client.lastName;
      this.motherLastName = client.motherLastName;
      this.identifier = client.identifier;
      this.profession = client.profession;
      this.nationality = client.nationality;
      this.maritalStatus = client.maritalStatus;

      this.contactData.phoneNumber = client.contactData.phoneNumber;
      this.contactData.cellphoneNumber = client.contactData.cellphoneNumber;
      this.contactData.email = client.contactData.email;

      this.contactData.address.street = client.contactData.address.street;
      this.contactData.address.number = client.contactData.address.number;
      this.contactData.address.unitNumber = client.contactData.address.unitNumber;
      this.contactData.address.region = client.contactData.address.region;
      this.contactData.address.city = client.contactData.address.city;
      this.contactData.address.aditionalInfo = client.contactData.address.aditionalInfo;

      this.creationDate = client.creationDate;
      this.lastUpdate = client.lastUpdate;

      this.selectedComuna = client.contactData.address.city;
      //this.selectedRegion = client.contactData.address.region;

      //console.log(client.contactData.address.region);
      //console.log(this.selectedComuna);
      //console.log(this.selectedRegion);

    },
    daysDifference(date) {
      var date1 = new Date(date);
      var date2 = new Date();
      // The number of milliseconds in one day
      var ONEDAY = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);
      // Convert back to days and return
      var value = 10 - Math.round(difference_ms / ONEDAY);
      if (value < 0) {
        value = 0;
      }
      return value;
    },
    getDaysStatusColor(days) {
      if (days > 3) return "success"
      if (days < 4) return "danger"
      return "primary"
    },
    getOrderStatusColor(status) {
      if (status == 'PENDING') return "warning"
      if (status == 'ISSUED') return "success"
      if (status == 'PROCESSED') return "primary"
      if (status == 'EXPIRED') return "danger"
      return "primary"
    },
    translateStatus(status) {
      if (status == 'PENDING') return "Por finalizar"
      if (status == 'ISSUED') return "Cotización emitida"
      if (status == 'PROCESSED') return "Procesada"
      if (status == 'EXPIRED') return "Cotización Expirada"
      return "Indeterminado"
    },
    getPopularityColor(num) {
      if (num > 90) return "success"
      if (num > 70) return "primary"
      if (num >= 50) return "warning"
      if (num < 50) return "danger"
      return "primary"
    },
    resetClientPassword(clientId) {
      //console.log(clientId);
      clientService.clientResetPassword(clientId)
        .then(response => {
          if (response) {
          this.$vs.notify({
            title: 'Password Reset',
            text: 'La contraseña ha sido enviada al usuario',
            color: 'primary',
            fixed: false,
          })
          }
        })
        .catch(error => {
          this.$log.error('Error calling ClientService.clientResetPassword :  - ', error);
        });
    }
  },
  mounted() {
    this.isMounted = true;
    //console.info("clients");
    //console.info(this.$store.state.dataListClient.clients);
  }
}

</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;
        .vs-table--search-input {
          width: 100%;
        }
      }
    }
    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }
    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;
        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      >span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search {
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          &+i {
            left: 1rem;
          }
          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }
    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;
        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }
    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>