import axios from 'axios';

const clientPanelAPIURL = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_CPA_API;

const clientPanelAPI = axios.create({
  baseURL: clientPanelAPIURL
});

export const setAuthHeader = (token) => {
  clientPanelAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

clientPanelAPI.interceptors.request.use((config) => {
  let authInfo = JSON.parse(localStorage.getItem('authInfo'));

  if (authInfo) {
    config.headers['Authorization'] = `Bearer ${ authInfo.access_token }`
  }

  return config;
}, error => {
  return Promise.reject(error)
});

export default clientPanelAPI;
