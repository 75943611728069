/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_QUOTATION(state, quotations) {
    state.quotations = quotations
  },
  UPDATE_QUOTATION(state, quotation) {
    const quotationIndex = state.quotations.findIndex((p) => p.id == quotation.id)
    Object.assign(state.quotations[quotationIndex], quotation)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.quotations.findIndex((p) => p.id == itemId)
    state.quotations.splice(ItemIndex, 1)
  },
}
